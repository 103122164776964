'use client';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { FC, ReactNode, useState } from 'react';
import superjson from 'superjson';
import { trpc } from './client';
import { SessionProvider, signOut, useSession } from 'next-auth/react';
import { useSnackbar } from 'notistack';

function getBaseUrl() {
  if (typeof window !== 'undefined') {
    return '';
  }

  if (process.env.NEXTAUTH_URL) {
    return process.env.NEXTAUTH_URL;
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

type Props = {
  children: ReactNode;
};

function InnerTRPCProvider(props: { children: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const { status } = useSession();

  const handleError = async (error: any) => {
    enqueueSnackbar(error.data?.message ?? error.message, {
      preventDuplicate: true,
      variant: 'error',
    });

    if (
      error.data?.code === 'UNAUTHORIZED' &&
      (status === 'authenticated' || status === 'loading')
    ) {
      await signOut({ callbackUrl: '/adhd' });
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
          },
        },
        queryCache: new QueryCache({
          onError: handleError,
        }),
        mutationCache: new MutationCache({
          onError: handleError,
        }),
      }),
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: `${getBaseUrl()}/api/trpc`,
          transformer: superjson,
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </trpc.Provider>
  );
}

export const TRPCProvider: FC<Props> = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <SessionProvider>
      <InnerTRPCProvider>{children}</InnerTRPCProvider>
    </SessionProvider>
  );
};
