'use client';

import { ConfirmProvider } from 'material-ui-confirm';

import React from 'react';

const AppConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: { variant: 'contained', autoFocus: true },
        cancellationButtonProps: { variant: 'outlined' },
        titleProps: { style: { padding: '1em', fontSize: '21px' } },
        dialogProps: {
          PaperProps: {
            sx: {
              borderRadius: '20px',
            },
          },
        },
        dialogActionsProps: {
          style: { padding: '1em' },
        },
      }}
    >
      {children}
    </ConfirmProvider>
  );
};

export default AppConfirmProvider;
