/* eslint-disable @next/next/no-img-element */

'use client';

import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { zdAdd, zdClose, zdOpen } from 'components/zendesk/zendesk';
import { AppContext } from 'context/AppProvider';
import { useLocale, useTranslations } from 'next-intl';
import { useContext } from 'react';

const RootFooter: React.FC = () => {
  // eslint-disable-next-line no-unused-vars
  const t = useTranslations('RootFooter');
  const locale = useLocale();
  const { isZendeskWidgetOpen, setIsZendeskWidgetOpen } = useContext(AppContext);

  const handleOpen = () => {
    if (setIsZendeskWidgetOpen) {
      setIsZendeskWidgetOpen(true);
    }
  };

  const handleClose = () => {
    if (setIsZendeskWidgetOpen) {
      setIsZendeskWidgetOpen(false);
    }
  };

  const handleToggleZendesk = () => {
    zdAdd(handleOpen, handleClose, locale);
    if (isZendeskWidgetOpen) {
      zdClose();
    } else {
      zdOpen();
    }
  };

  return (
    <Box
      sx={{
        maxHeight: '56px',
        height: '56px',
        width: '100%',
        background: `url('/assets/orange-shape.svg')`,
        backgroundSize: '100% 56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: '1000',
      }}
    >
      <Typography variant="body2">
        {t.rich('need-help', {
          email: (chunk) => (
            <Link
              color="secondary"
              style={{ cursor: 'pointer' }}
              href="mailto:info@claritypediatrics.com"
            >
              {chunk}
            </Link>
          ),
          chat: (chunk) => (
            <Link color="secondary" style={{ cursor: 'pointer' }} onClick={handleToggleZendesk}>
              {chunk}
            </Link>
          ),
        })}
      </Typography>
    </Box>
  );
};

export default RootFooter;
