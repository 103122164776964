'use client';

import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'context/AppProvider';
import { Container } from '@mui/material';
import { ZENDESK_CONVERSATION_FIELDS } from 'shared/lib/constants';
import { zdCustomFields } from './zendesk';
import { usePathname } from 'navigation';
import { getCookie } from 'cookies-next';
import { COOKIE_ONBOARDING } from 'shared/lib/constants';

export const ZendeskFieldValues = () => {
  const { healthieFieldId, stateFieldId, stateTextFieldId, urlFieldId } =
    ZENDESK_CONVERSATION_FIELDS;
  const pathname = usePathname();
  const cookie = getCookie(COOKIE_ONBOARDING);
  const [healthieIdValue, setHealthieIdValue] = useState<string | null>();
  const [stateIdValue, setStateValue] = useState<string | null>();
  const [stateTextIdValue, setStateTextValue] = useState<string | null>();
  const [pathnameIdValue, setPathnameValue] = useState<string | null>();

  const screenId = cookie ? JSON.parse(cookie).screenId : '';

  const { user } = useContext(AppContext);
  const { id: patientId, healthieId } = user?.patient || {};

  // necessary hoop to avoid errors with SSR hydration mismatch
  useEffect(() => {
    setHealthieIdValue(healthieId);
    setPathnameValue(pathname);
    setStateValue(user?.patient?.address?.state?.toLowerCase());
    setStateTextValue(user?.patient?.address?.state?.toLowerCase());
    zdCustomFields();
  }, [patientId, healthieId, screenId, pathname, user?.patient?.address?.state]);

  return (
    <Container sx={{ display: 'none' }}>
      <label id={`${stateFieldId}`} data-testid={`${stateFieldId}`}>
        {stateIdValue}
      </label>
      <label id={`${stateTextFieldId}`} data-testid={`${stateTextFieldId}`}>
        {stateTextIdValue}
      </label>
      <label id={`${healthieFieldId}`} data-testid={`${healthieFieldId}`}>
        {healthieIdValue}
      </label>
      <label id={`${urlFieldId}`} data-testid={`${urlFieldId}`}>
        {pathnameIdValue}
      </label>
    </Container>
  );
};
