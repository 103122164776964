import { PrismaClient } from '@prisma/client';
import { readReplicas } from '@prisma/extension-read-replicas';

const createExtendedClient = (): PrismaClient => {
  const client = new PrismaClient();

  if (process.env.DATABASE_REPLICA_1_URL) {
    return client.$extends(
      readReplicas({
        url: process.env.DATABASE_REPLICA_1_URL,
      }),
    ) as unknown as PrismaClient;
  }

  return client;
};

const globalForPrisma = globalThis as unknown as {
  prisma: PrismaClient | undefined;
};

const prisma = globalForPrisma.prisma ?? createExtendedClient();

export * from '@prisma/client';
export * from './middleware';

export { prisma };

if (process.env.NODE_ENV !== 'production') globalForPrisma.prisma = prisma;
