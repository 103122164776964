import { Suspense } from 'react';

const withSuspense = (Component: any) => {
  return (props: any) => {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };
};

export default withSuspense;
