'use client';

import Script from 'next/script';

export const FormsScript = () => {
  const handler = () => {
    window.jotformEmbedHandler("iframe[id='JotFormIFrame-clarity'", 'https://form.jotform.com/');
  };

  return (
    <Script
      src="https://form.jotform.com/s/umd/latest/for-form-embed-handler.js"
      onLoad={handler}
    />
  );
};

export default FormsScript;
